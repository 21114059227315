import request from "@src/plugins/request"

// 标讯数据列表
export const urlCommonResRecommendList = function (data) {
  return request({
    url: `/erp/commonRes/recommend/list`,
    method: 'POST',
    data
  })
};
// 企业资质
export const urlCommonQualificationTypeProPage = function (data) {
  return request({
    url: `/common/qualification/type/pro/page`,
    method: 'POST',
    data
  })
};
// 业务关键词
export const urlCommonSearchHot = function (data) {
  return request({
    url: `/common/search/hot?${data}`,
    method: 'POST',

  })
};
// 获取用户编辑信息
export const urlErpTbFocusMy = function (data) {
  return request({
    url: `/erp/tb/focus/my`,
    method: 'POST',
    data
  })
};
// 保存用户编辑信息
export const urlErpTbFocusSave = function (data) {
  return request({
    url: `/erp/tb/focus/save`,
    method: 'POST',
    data
  })
};
// 获取业务关键字
export const urlCommonSearchAuto = function (data) {
  return request({
    url: `/common/search/auto`,
    method: 'POST',
    data
  })
};
// 投标首页详情
export const urlErpCommonResDetail2 = function (data) {
  return request({
    url: `/erp/commonRes/detail2?${data}`,
    method: 'GET',
  })
};

// 工程招标首页数据
export const urlErpCommonResListPage2 = function (data) {
  return request({
    url: `/erp/commonRes/listPage2`,
    method: 'POST',
    data
  })
};
// 检查工程招标是否收藏
export const urlCommonCollectionCheck = function (data) {
  return request({
    url: `/common/collection/check`,
    method: 'POST',
    data
  })
};
// 工程招标收藏
export const urlCommonCollectionAddOrDel = function (data) {
  return request({
    url: `/common/collection/addOrDel`,
    method: 'POST',
    data
  })
};
// 分包采集首页数据
export const urlErpZbProjectNoticeListPageWithPrj = function (data) {
  return request({
    url: `/erp-zb-project-notice/listPageWithPrj`,
    method: 'POST',
    data
  })
};
// 分包采集数据列表
export const urlDcEnterpriseHighPage = function (data) {
  return request({
    url: `/dcEnterprise/high/page`,
    method: 'POST',
    data
  })
};
// 分包采集详情
export const urlErpZbProjectDetailByUUID = function (data) {
  return request({
    url: `/erp-zb-project/detailByUUID?${data}`,
    method: 'GET'
  })
};
// 分包采集详情公告列表
export const urlErpZbProjectNoticeDetailWithPrj = function (data) {
  return request({
    url: `/erp-zb-project-notice/detailWithPrj?${data}`,
    method: 'GET'
  })
};
// 分包采集详情公告列表
export const urlErpZbProjectListPage = function (data) {
  return request({
    url: `/erp-zb-project/listPage`,
    method: 'POST',
    data
  })
};
