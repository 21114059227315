<template>
  <div class="main">
    <div class="main_box" v-loading="loading">
      <div class="main_title">
        <span>{{projectData.projectName | changeTitle()}}</span>
      </div>
      <div class="flex_center main_label">
        <div class="flex_center main_label_l">
          <div class="main_label_item">
            <span>{{projectData.province || ''}} {{ projectData.city ? '. ' + projectData.city:''}}</span>
          </div>
          <div class="main_label_item">
            <span>{{projectData.industryv3 || ''}}</span>
          </div>
        </div>
        <div class="main_label_r">
<!--          <span> 【阅读次数：21 】</span>-->
        </div>
      </div>
      <div class="main_tabs">
        <el-tabs type="border-card" v-model="tabsItem" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.name" v-for="(item, index) in tabsList" :key="item.value">
            <div class="main_tabs_box" v-for="(items, indexs) in filterProjectData" :key="indexs">
              <div class="main_tabs_title">
                <span>{{ items.projectTitle }}</span>
              </div>
              <div class="flex_center main_tabs_label">
                <div class="flex_center main_tabs_label_l">
                  <!--<div class="flex_row_center main_tabs_label_item">
                    <i class="el-icon-document"></i>
                    <span>申请保函</span>
                  </div>-->
                  <div class="flex_row_center main_tabs_label_item" @click="toLink(items)" v-if="items.originalUrl">
                    <i class="el-icon-paperclip"></i>
                    <span>原文链接地址</span>
                  </div>
                </div>
                <div class="main_tabs_label_r">
                  <span>发布时间：{{items.publishDate | timeFilter()}}</span>
                </div>
              </div>
              <div v-html="items.body"></div>
            </div>
            <template v-if="!filterProjectData.length">
              <el-empty description="暂无数据"></el-empty>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="flex_row_center main_management">
        <el-button v-if="projectData.erpTbProjectId === null" :disabled="isDisabled" @click="onManagement">加入管理</el-button>
        <el-button @click="onManagement" v-else>进入管理</el-button>
      </div>
      <div class="flex main_collect">
        <el-button size="mini" icon="el-icon-star-on" @click="getCommonCollectionAddOrDel" :loading="loading1" v-show="isCollect" style="color: #ffc94b;">收藏</el-button>
        <el-button size="mini" icon="el-icon-star-off" @click="getCommonCollectionAddOrDel" :loading="loading1" v-show="!isCollect">收藏</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { urlErpCommonResDetail2, urlCommonCollectionCheck, urlCommonCollectionAddOrDel } from '@/api/recruit'
  export default {
    name: "projectBiddingDetails",
    data() {
      return {
        projectData: {},
        projectList: [],
        tabsList: [
          { name: '招标公告', value: 0 },
          { name: '变更公告', value: 1 },
          { name: '资格预审', value: 2 },
          { name: '开标结果', value: 3 },
          { name: '澄清答疑', value: 4 },
          { name: '流标公告', value: 5 },
          { name: '中标公告', value: 6 },
          { name: '履约信息', value: 7 }
        ],
        tabsItem: '招标公告',
        loading: true,
        loading1: false,
        isCollect: false,
        isDisabled: false
      }
    },
    filters: {
      timeFilter(time) {
        if (!time) return "-";
        let pulishTime = time.split(' ')[0];
        return pulishTime
      },
      changeTitle(projectTitle){
        if(!projectTitle){
          return '-'
        }
        let newProjectTitle = projectTitle.replace(/&nbsp;/g, '')
        return newProjectTitle
      },
    },
    computed: {
      filterProjectData() {
        return this.projectList.filter(item => item.noticeNature === this.tabsItem);
      }
    },
    mounted() {
      window.addEventListener('focus', this.handleFocus)
      const { tenderingCode, tenderingName } = this.$route.query;
      this.tenderingCode = tenderingCode;
      this.tabsItem = tenderingName ? tenderingName : '招标公告';
      this.getErpCommonResDetail2();
      this.getCommonCollectionCheck();
    },
    methods: {
      handleFocus() {
        this.getErpCommonResDetail2();
      },
      onManagement() {
        const { protocol, host } = window.location;
        const domain = `${protocol}//${host}`;
        if (this.projectData.erpTbProjectId === null && this.projectData.zbdateStatus !== '已开标') {
          //  加入管理的地址
          window.open(`${domain}/bxbh/#/bidder/allProjects?tenderingCode=${this.tenderingCode}`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/allProjects?tenderingCode=${this.tenderingCode}`, '_blank');
        } else if (this.projectData.erpTbProjectId !== null) {
          //  进入管理的地址
          window.open(`${domain}/bxbh/#/bidder/manageProjects`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/manageProjects`, '_blank');
        }
      },
      async getCommonCollectionCheck() {
        const param = {
          bid: this.tenderingCode,
          type: "publicResources"
        };
        const res = await urlCommonCollectionCheck(param);
        this.isCollect = res.data;
      },
      async getCommonCollectionAddOrDel() {
        this.loading1 = true;
        const param = {
          bid: this.projectData.tenderingCode,
          type: "publicResources"
        };
        const res = await urlCommonCollectionAddOrDel(param);
        if (res.code === 200) this.isCollect = !this.isCollect
        this.loading1 = false;
      },
      toLink(e) {
        window.open(e.originalUrl, "_blank");
      },
      handleClick(tab) {
        if (this.tabsItem === tab.label) return;
        this.tabsItem = tab.label;
      },
      async getErpCommonResDetail2() {
        this.loading = true;
        const param = `tenderingCode=${this.tenderingCode}`;
        const res = await urlErpCommonResDetail2(param);
        this.projectData = res.data;
        /*<el-button v-show="projectData.erpTbProjectId === null && projectData.zbdateStatus === null" disabled>加入管理1</el-button>
          <el-button v-show="projectData.erpTbProjectId === null && projectData.zbdateStatus !== '已开标'" @click="onManagement">加入管理2</el-button>*/

        if (res.data.erpTbProjectId === null && res.data.zbdateStatus === '已开标') this.isDisabled = true
        if (!res.data.noticeList || res.data.noticeList.length === 0) {
          this.projectList = [res.data];
        } else {
          this.projectList = res.data.noticeList;
        }
        this.loading = false;
      }
    },
    beforeDestroy() {
      window.removeEventListener('focus', this.handleFocus)
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #4E4E4E;
  }
  /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #1890FF;
  }
  .main {
    text-align: left;
    .main_box {
      .main_title {
        font-size: 20px;
        font-weight: bold;
        color: #212121;
        padding-bottom: 12px;
      }
      .main_label {
        justify-content: space-between;
        padding-bottom: 20px;
        .main_label_l {
          .main_label_item {
            height: 28px;
            padding: 0 6px;
            margin-right: 12px;
            border-radius: 4px;
            background-color: #F6F7FB;
            font-size: 16px;
            color: #4E4E4E;
            line-height: 28px;
          }
        }
        .main_label_r {
          font-size: 14px;
          color: #4E4E4E;
        }
      }
      .main_tabs {
        padding-bottom: 30px;
        .main_tabs_box {
          padding: 20px;
          .main_tabs_title {
            font-size: 18px;
            font-weight: 500;
            color: #212121;
            padding-bottom: 20px;
          }
          .main_tabs_label {
            padding-bottom: 20px;
            justify-content: space-between;
            .main_tabs_label_l {
              .main_tabs_label_item {
                height: 28px;
                padding: 0 6px;
                margin-right: 10px;
                border-radius: 4px;
                background-color: #F5F9FF;
                font-size: 16px;
                color: #1890FF;
                line-height: 1;
                cursor: pointer;
                > span {
                  padding-left: 4px;
                }
              }
            }
            .main_tabs_label_r {
              font-size: 14px;
              color: #4E4E4E;
            }
          }
        }
      }
      .main_management {
        padding-bottom: 30px;
      }
      .main_collect {
        justify-content: flex-end;
        padding-bottom: 30px;
      }
    }
  }
</style>
